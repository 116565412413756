import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from './Button';

function TopBanner({ text, expandedText, translate }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // console.log(`${translate('navbar.banner')}`);

  return (
    <div className={`top-banner ${expanded ? 'expanded' : ''}`}>
      <div className="d-flex flex-column flex-sm-row align-items-center">
        {text ? (
          // eslint-disable-next-line react/no-danger
          <p className="px-5 pt-2 text-center" dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <p className="px-5 pt-2 text-center">
            <Translate id="navbar.banner" />
          </p>
        )}
        {expandedText && <Button small text={translate(expanded ? 'default.close' : 'default.read-more')} onClick={handleExpandClick} />}
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      {expanded && <p className="pb-3 px-5 text-center" dangerouslySetInnerHTML={{ __html: expandedText }} />}
    </div>
  );
}

export default withLocalize(TopBanner);
