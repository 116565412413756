/* eslint-disable max-len */

const testimonials = {
  sv: [
    {
      id: 1,
      text: 'Jag och min man köper Golfamore varje år. För oss pensionärer är det underbart, vi får spela många nya golfbanor till ett vettigt pris. Vi har även träffat många nya vänner på golfbanan tack vare Golfamore.',
      name: 'Anita'
    },
    {
      id: 2,
      text: 'Tack vara Golfamore har vi upptäckt flera nya banor vi aldrig skulle ha spelat annars. Vår favoritbana är Tortuna i Spanien som dessutom är med i PLUS så vi kan spela den flera gånger.',
      name: 'Peder'
    },
    {
      id: 3,
      text: 'Vi brukar fråga folk vi spelar med om de har Golfamore, många har det. Men vi blir alltid lika förvånade när någon svara att de inte har Golfamore. För oss är det en självklarhet att köpa det varje år.',
      name: 'Maria'
    },
    {
      id: 4,
      text: 'Golfamore är billigt med tanke på vad man får. Halv greenfee på så många fina banor. Jag brukar spela med mina vänner som också har Golfamore och vi är väldigt nöjda',
      name: 'Jens'
    },
    {
      id: 5,
      text: 'Det är tur att jag bara betalar halv greenfee när jag spelar med tanke på hur många golfbollar jag alltid slår bort. Tack vare Golfamore har jag råd att köpa nya bollar.',
      name: 'Ole'
    }
  ],
  de: [
    {
      id: 1,
      text: 'Mein Mann und ich kaufen Golfamore jedes Jahr. Für uns als Rentner ist es einfach herrlich, auf vielen neuen Golfanlagen zu einem vernünftigen Preis spielen zu können. Auch haben wir dank Golfamore viele neue Freundschaften auf dem Golfplatz geschlossen.',
      name: 'Anita'
    },
    {
      id: 2,
      text: 'Dank Golfamore haben wir mehrere neue Anlagen entdeckt, auf denen wir sonst nie gespielt hätten. Unser Lieblingsplatz ist Tortuna in Spanien, der auch in PLUS enthalten ist, sodass wir dort gleich mehrmals spielen können.',
      name: 'Peder'
    },
    {
      id: 3,
      text: 'Normalerweise fragen wir Leute, mit denen wir spielen, ob sie Golfamore haben, was oft der Fall ist. Genauso überrascht sind wir aber auch dann, wenn jemand Golfamore nicht hat. Für uns ist die jährliche Anschaffung von Golfamore selbstverständlich geworden.',
      name: 'Maria'
    },
    {
      id: 4,
      text: 'Golfamore ist günstig, wenn man bedenkt, was man dafür bekommt. Halbe Greenfee auf so vielen schönen Plätzen. Normalerweise spiele ich mit meinen Freunden, die auch Golfamore haben und wir sind alle sehr zufrieden.',
      name: 'Jens'
    },
    {
      id: 5,
      text: 'Zum Glück zahle ich beim Spielen nur die halbe Greenfee, wenn ich bedenke, wie viele Golfbälle ich immer wegschieße. Dank Golfamore kann ich mir neue Bälle leisten.',
      name: 'Ole'
    }
  ],
  dk: [
    {
      id: 1,
      text: 'Min mand og jeg køber Golf-hæftet hvert år. For os pensionister er det skønt, vi får spillet mange nye golfbaner til en fornuftig pris. Vi har også mødt mange nye venner på golfbanen takket være Golfamore.',
      name: 'Anita'
    },
    {
      id: 2,
      text: 'Takket være Golfamore har vi opdaget flere nye baner, vi ellers aldrig ville have spillet. Vores favoritbane er Tortuna i Spanien, som også er en del af PLUS, så vi kan spille den flere gange.',
      name: 'Peder'
    },
    {
      id: 3,
      text: 'Vi plejer at spørge folk, vi spiller med, om de har Golfamore mange har det. Men vi bliver altid lige så overraskede, når nogen svarer, at de ikke har Golfamore. For os er det en selvfølge at købe den hvert år.',
      name: 'Maria'
    },
    {
      id: 4,
      text: 'Golfamore er billigt i betragtning af, hvad man får. Halv greenfee på så mange fine baner. Jeg plejer at spille med mine venner, som også har Golfamore, og vi er meget glade.',
      name: 'Jens'
    },
    {
      id: 5,
      text: 'Det er heldigt, at jeg kun betaler halvdelen af ​​greenfeen, når jeg spiller, i betragtning af hvor mange golfbolde jeg altid smider væk. Takket være Golfamore har jeg råd til at købe nye bolde.',
      name: 'Ole'
    }
  ]
};

export default testimonials;
