import React from 'react';
import { Container, Row } from 'reactstrap';
import { Translate } from 'react-localize-redux';

function Error404() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('error-404');
    window.scrollTo(0, 0);
    return function cleanup() {
      document.body.classList.remove('error-404');
    };
  });
  return (
    <>
      <div
        className="background-img"
        // style={{
        //   backgroundImage:
        //     "url(" + require("assets/img/sections/martin-knize.jpg") + ")"
        // }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 className="title mt-5 pt-5">
              404
              <br />
              <p className="error-msg">
                <Translate id="error-404.sub-title" />
              </p>
            </h1>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Error404;
