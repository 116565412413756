const SUPPORTED_LANGUES = ['sv', 'dk', 'no', 'fi', 'en', 'de', 'cs'];

const getLanguage = () => window.localStorage.getItem('gh.language') || detectLanguage();
export default getLanguage;

export const detectLanguage = () => {
  const languageCode = getLanguageCode(window.location.pathname);
  window.localStorage.setItem('gh.language', languageCode);
  return languageCode;
};

const getLanguageCode = (url) => {
  let language = url.split('/')[1];
  language = isSupportedLanguage(language) ? language : navigator.language?.substr(0, 2);
  return isSupportedLanguage(language) ? language : 'en';
};

export const getBrowserlanguage = () => {
  return navigator.language?.substring(0, 5);
};

const isSupportedLanguage = (language) => SUPPORTED_LANGUES.includes(language);

export const extractDistinctCountries = (courses) => {
  const map = new Map();
  return courses
    .map((c) => ({ id: c.cId, name: c.country }))
    .filter((c) => {
      if (map.has(c.id)) return false;
      map.set(c.id, true);
      return true;
    })
    .sort((a, b) => a.id - b.id);
};

export const extractDistinctDistricts = (courses) => {
  const map = new Map();
  return courses
    .map((c) => ({ id: c.dId, name: c.district, country: c.cId }))
    .filter((c) => {
      if (map.has(c.id)) return false;
      map.set(c.id, true);
      return true;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const isQuotaExceeded = (e) => {
  let quotaExceeded = false;
  if (e) {
    if (e.code) {
      // eslint-disable-next-line default-case
      switch (e.code) {
        case 22:
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true;
          }
          break;
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true;
    }
  }
  return quotaExceeded;
};
