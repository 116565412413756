import React from 'react';
import { Link } from 'react-router-dom';
import { Button as RButton } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import getPath from '../assets/routes/paths';
import getLanguage from '../services/helpers';

const svgArrow =
  'M5 13h11.17l-4.88 4.88a1 1 0 000 1.42 1 1 0 001.41 0l6.59-6.59a1 1 0 000-1.41l-6.58-6.6a1 1 0 10-1.41 1.41L16.17 11H5a1 1 0 00-1 1 1 1 0 001 1z'; // eslint-disable-line

export const Arrow = () => (
  <svg width="18" height="18" alt="right arrow" viewBox="0 0 26 26">
    <g fill="currentColor" fillRule="evenodd">
      <path d={svgArrow} />
    </g>
  </svg>
);

export const ArrowDown = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#fff" fillRule="evenodd" clipRule="evenodd">
    <path d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z" />
  </svg>
);

const Button = ({
  text,
  link,
  subPage,
  qp,
  externalLink,
  onClick,
  small,
  arrow = true,
  inProgress = false,
  arrowDirection = 'right',
  className,
  style,
  color
}) => {
  const language = getLanguage();

  return (
    <>
      {onClick ? (
        <RButton onClick={onClick} color={color} className="link-button btn" style={small && { padding: '0 10px' }}>
          {inProgress && <Spinner color="light" size="sm" style={{ marginRight: 10 }} />}
          {text}
          {arrow && (arrowDirection === 'right' ? <Arrow /> : <ArrowDown />)}
        </RButton>
      ) : link ? (
        <Link
          to={getPath(language, link) + (subPage || '') + (qp ? `?${qp}` : '')}
          className={`link-button ${className || ''} ${color ? `btn-${color}` : ''}`}
          style={style}
        >
          {text}
          {arrow && <Arrow />}
        </Link>
      ) : (
        <a
          href={externalLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`link-button ${className || ''} ${color ? `btn-${color}` : ''}`}
          style={small && { padding: '5px 20px' }}
        >
          {text}
          {arrow && <Arrow />}
        </a>
      )}
    </>
  );
};

export default Button;
