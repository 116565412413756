import React, { useState, useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import cmp from 'semver-compare';

import * as api from '../services/api';
import { Store } from '../Store';
import Button from './Button';
import getLanguage from '../services/helpers';
import getPath from '../assets/routes/paths';

import logotypeGolfamoreWhite from '../assets/img/logo-golfamore-white.png';
import logotypeGolfamoreBlack from '../assets/img/logo-golfamore-black.png';
import TopBanner from './TopBanner';
import styles from './Navbar.module.scss';

function MainNavbar({ translate }) {
  const { state } = useContext(Store);

  const [showCampaignBanner, setShowCampaignBanner] = useState(false);

  const languageCode = getLanguage();

  useEffect(() => {
    (async () => {
      const { response } = await api.getCurrentCampaign();
      if (response?.bannerText) {
        setShowCampaignBanner({ ...response, show: true });
      }
      // else {
      // setShowCampaignBanner({ show: true });
      // }
    })();
  }, []);

  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      {bodyClick && (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.remove('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      )}

      <Navbar className="sticky-top flex-column" id="navbar-main" expand="lg">
        {showCampaignBanner?.show && (
          <TopBanner text={showCampaignBanner.bannerText} expandedText={showCampaignBanner.bannerTextExpanded} />
        )}
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to={`/${languageCode}/`} tag={Link}>
              <img src={logotypeGolfamoreWhite} id="gh-logotype-white" alt="logtype" />
              <img src={logotypeGolfamoreBlack} id="gh-logotype-black" alt="logtype" className="d-none" />
            </NavbarBrand>

            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              aria-label="menu"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {languageCode !== 'dk' && (
                <MyNavItem to={getPath(languageCode, 'HowItWorks')} tag={Link}>
                  <Translate id="navbar.how-it-works" />
                </MyNavItem>
              )}

              <MyNavItem to={getPath(languageCode, 'GolfCourses')} tag={Link}>
                <Translate id="navbar.golf-courses" />
              </MyNavItem>

              <MyNavItem to={getPath(languageCode, 'Activate')} tag={Link}>
                <Translate id="navbar.activate" />
              </MyNavItem>

              {'sv' === languageCode && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <Translate id="navbar.events.title" />
                  </DropdownToggle>
                  <DropdownMenu className={styles.submenu}>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'Events')}>
                      <Translate id="navbar.events.submenu.all-events" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'Competitions')}>
                      <Translate id="navbar.events.submenu.competitions" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'CompetitionGolfamoreTrophy')}>
                      <Translate id="navbar.events.submenu.gt" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'CompetitionUnderParChallenge')}>
                      <Translate id="navbar.events.submenu.upc" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'CompetitionFootJoyMasters')}>
                      <Translate id="navbar.events.submenu.fjm" />
                    </DropdownItem>
                    {languageCode === 'sv' && (
                      <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'Extreme')}>
                        Extreme
                      </DropdownItem>
                    )}
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'CompetitionIndoorOpen')}>
                      <Translate id="navbar.events.submenu.ido" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'Calendar')}>
                      <Translate id="navbar.events.submenu.calendar" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'Tours')}>
                      <Translate id="navbar.events.submenu.tours" />
                    </DropdownItem>
                    <DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'OrderOfMerit')}>
                      <Translate id="navbar.events.submenu.oom" />
                    </DropdownItem>
                    {/*<DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'TourBroHof')}>*/}
                    {/*  <Translate id="navbar.events.submenu.bro-hof" />*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'TourTheNational')}>*/}
                    {/*  <Translate id="navbar.events.submenu.the-national" />*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'TourVallda')}>*/}
                    {/*  <Translate id="navbar.events.submenu.vallda" />*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'TourOsteraker')}>*/}
                    {/*  <Translate id="navbar.events.submenu.osteraker" />*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem tag={Link} className="not-default" to={getPath(languageCode, 'TourHalmstad')}>*/}
                    {/*  <Translate id="navbar.events.submenu.halmstad" />*/}
                    {/*</DropdownItem>*/}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {state.user?.token ? (
                <>
                  <MyNavItem to={getPath(languageCode, 'Account')} tag={Link}>
                    <Translate id="navbar.my-page" />
                  </MyNavItem>
                </>
              ) : (
                <>
                  <MyNavItem to={getPath(languageCode, 'Account')} tag={Link}>
                    <i className="fa fa-user" style={{ fontSize: '12px' }} />
                    <Translate id="navbar.login" />
                  </MyNavItem>
                </>
              )}

              <NavItem className="d-flex align-items-center">
                <Button
                  text={translate('default.buy-golfhaftet')}
                  link="Webshop"
                  arrow={false}
                  className="my-0"
                  style={{ background: '#000000' }}
                />
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default withLocalize(MainNavbar);

const MyNavItem = ({ children, ...props }) => {
  const { state } = React.useContext(Store);

  const handleClick = (event) => {
    if (process.env.NODE_ENV === 'development') return;
    if (state.settings.versionAvailable && cmp(state.settings.versionAvailable, process.env.REACT_APP_VERSION)) {
      if (navigator.onLine) {
        event.preventDefault();
        window.location.href = props.to;
      }
    }
  };

  return (
    <NavItem>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <NavLink {...props} onClick={handleClick}>
        {children}
      </NavLink>
    </NavItem>
  );
};
