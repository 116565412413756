import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import './services/appInitialize';

import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { ErrorBoundary } from '@sentry/react';

import './assets/css/bootstrap.min.css';
import './assets/scss/paper-kit.scss';

import App from './App';
import { StoreProvider } from './Store';
import getLanguage from './services/helpers';

ReactDOM.render(
  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/#customizing-the-widget
  <ErrorBoundary
    // fallback={FallbackComponent}
    showDialog
    dialogOptions={{ lang: getLanguage() }}
  >
    <StoreProvider>
      <LocalizeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LocalizeProvider>
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
