/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useContext, Suspense } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import qs from 'qs';

import sv from './assets/lang/sv.json';
import en from './assets/lang/en.json';
import dk from './assets/lang/dk.json';
import no from './assets/lang/no.json';
import fi from './assets/lang/fi.json';
import de from './assets/lang/de.json';
// import cs from './assets/lang/cs.json';

import initializeGTM from './services/googleTagManager';

import Error404 from './views/Error404';

import MainNavbar from './components/Navbar';
import MainFooter from './components/Footer';
import VersionCheck from './components/VersionCheck';

import { Store } from './Store';
import * as api from './services/api';
import { detectLanguage } from './services/helpers';
import getPath from './assets/routes/paths';
import routes from './assets/routes';

const PrivateRoute = ({ component: Component, user, languageCode, props, ...rest }) => {
  const { state, dispatch } = useContext(Store);
  const path = rest?.location.pathname + rest?.location.search;
  if (state.lastPath !== path) {
    dispatch({ type: 'LAST_PATH', payload: path });
  }
  return <Route {...rest} render={() => (user?.token ? <Component {...props} /> : <Redirect to={getPath(languageCode, 'Login')} />)} />;
};

function App(props) {
  const { state, dispatch } = useContext(Store);
  const languageCode = detectLanguage();
  const location = useLocation();

  const isWebForm = location?.pathname.includes('web-form');

  useEffect(() => {
    initializeGTM();
    checkForTest();
  }, []);

  useEffect(() => {
    const { initialize, addTranslationForLanguage, setActiveLanguage } = props;

    // https://github.com/ryandrewjohnson/react-localize-redux/issues/155#issuecomment-730163623
    const onMissingTranslation = ({ defaultTranslation }) => {
      if (typeof defaultTranslation === 'string') {
        // do anything to change the defaultTranslation as you wish
        return defaultTranslation;
      }
      // that's the code that can fix the issue
      return renderToStaticMarkup(defaultTranslation);
    };

    initialize({
      languages: [
        { name: 'Swedish', code: 'sv' },
        { name: 'Danish', code: 'dk' },
        { name: 'Norwegian', code: 'no' },
        { name: 'Finnish', code: 'fi' },
        { name: 'English', code: 'en' },
        { name: 'German', code: 'de' }
        // { name: 'Czech', code: 'cs' }
      ],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        onMissingTranslation,
        defaultLanguage: 'en'
      }
    });

    addTranslationForLanguage(en, 'en');
    addTranslationForLanguage(sv, 'sv');
    addTranslationForLanguage(dk, 'dk');
    addTranslationForLanguage(no, 'no');
    addTranslationForLanguage(fi, 'fi');
    addTranslationForLanguage(de, 'de');
    // addTranslationForLanguage(cs, 'cs');

    setActiveLanguage(languageCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Initialize new store information
    if (!state.mapPosition) {
      dispatch({
        type: 'MAP_POSITION_DATA',
        payload: {
          center: {
            lat: 50.896824,
            lng: 10.576284
          },
          zoom: 4,
          activeIconId: null
        }
      });
    }
    const fetchCourses = async () => {
      // Refetch only if it's at least 1 hour since last fetch
      if (!state.courses.fetched || Date.now() - state.courses.fetched > 60000 * 60 * 1) {
        try {
          const { response: courses } = await api.getGolfCourses(true);
          if (courses) {
            dispatch({ type: 'COURSES_FETCHED', payload: courses });
          }

          const { response: allCourses } = await api.getGolfCourses(false);
          if (allCourses) {
            const allCoursesWithLat = allCourses.filter((c) => c.lat);
            dispatch({ type: 'COURSES_ALL_FETCHED', payload: allCoursesWithLat });
          }
        } catch {}
      }
    };
    fetchCourses();
  }, [state.courses.fetched, languageCode, dispatch]);

  const checkForTest = () => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    dispatch({ type: 'TEST_MODE', payload: query.test === 'J' });
  };
  const routeComponents = routes.map((r) =>
    r.privateRoute ? (
      <PrivateRoute exact path={r.path} component={r.component} key={r.path} languageCode={languageCode} user={state.user} />
    ) : (
      <Route exact path={r.path} component={r.component} key={r.path} />
    )
  );

  return (
    <>
      {/* <Layout {...props}> */}
      <VersionCheck path={location.pathname} language={languageCode} />

      <Suspense fallback={<p />}>
        <PayPalScriptProvider
          options={{
            'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
            intent: 'capture',
            currency: 'EUR',
            'disable-funding': 'card,venmo,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort'
          }}
        >
          {!isWebForm && <MainNavbar />}
          <Switch>
            {routeComponents}
            <Route component={Error404} />
          </Switch>
          <MainFooter />
        </PayPalScriptProvider>
      </Suspense>
      {/* </Layout> */}
    </>
  );
}

// App.whyDidYouRender = true;

export default withLocalize(App);
