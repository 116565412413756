import React, { useEffect } from 'react';

import identify from './services/appInitialize';
import { extractDistinctCountries, extractDistinctDistricts, isQuotaExceeded } from './services/helpers';

export const Store = React.createContext();

const localStorageData = localStorage.getItem('gh');
let localState;
if (localStorageData) {
  localState = JSON.parse(localStorageData);
}

const initialState = {
  courses: {
    data: [],
    fetched: null
  },
  countries: {
    data: [],
    fetched: null
  },
  districts: {
    data: [],
    fetched: null
  },
  cities: {
    data: [],
    fetched: null
  },
  allCourses: [],
  coursesFilter: {},
  mapPosition: {
    center: {
      lat: 50.896824,
      lng: 10.576284
    },
    zoom: 4,
    activeIconId: null
  },
  reviews: {
    data: [],
    fetched: null
  },
  news: {
    data: [],
    fetched: null
  },
  retailers: {
    data: [],
    fetched: null
  },
  user: null,
  account: {},
  settings: {
    versionAvailable: null,
    versionLastChecked: null
  },
  affiliate: null,
  cart: null,
  cartUser: null,
  lastPath: null,
  testMode: false
};

function reducer(state, action) {
  // console.log('action.type', action.type, action.payload);
  switch (action.type) {
    case 'LANGUAGE_UPDATED': {
      return {
        ...state,
        settings: {
          ...state.settings,
          language: action.payload
        }
      };
    }
    case 'USER_LOGGED_IN':
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload
        }
      };
    case 'USER_UPDATED':
    case 'USER_FETCHED':
      identify(action.payload);
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload,
          fetched: Date.now()
        }
      };
    case 'USER_LOGGED_OUT':
      return {
        ...state,
        user: null
      };
    case 'COURSES_FETCHED':
      return {
        ...state,
        courses: {
          data: action.payload
            .map((course) => {
              const c = { ...course };
              c.images = c.album && c.album.split(';;;');
              // c.top = [1562, 1391, 1958, 5289, 1675, 1626].includes(c.id);
              c.top = true;
              return c;
            })
            .sort((a, b) => a.golfclub.localeCompare(b.golfclub)),
          fetched: Date.now()
        },
        countries: {
          data: extractDistinctCountries(action.payload),
          fetched: Date.now()
        },
        districts: {
          data: extractDistinctDistricts(action.payload),
          fetched: Date.now()
        }
      };
    case 'COURSES_ALL_FETCHED':
      return {
        ...state,
        allCourses: {
          data: action.payload.map((c) => ({
            ...c,
            search: c.golfclub
              .normalizeText('NFD')
              .toLowerCase()
              .replace(/[\u0300-\u036f]/g, '')
              .replace('/ø/g', 'o')
          })),
          fetched: Date.now()
        }
      };
    case 'COURSES_FILTER':
      return {
        ...state,
        coursesFilter: {
          ...state.coursesFilter,
          ...action.payload
        }
      };
    case 'MAP_POSITION_DATA':
      // console.log('MAP_POSITION_DATA', action.payload);
      return {
        ...state,
        mapPosition: {
          ...state.mapPosition,
          ...action.payload
        }
      };
    case 'CITIES_FETCHED':
      return {
        ...state,
        cities: {
          data: action.payload,
          fetched: Date.now()
        }
      };
    case 'REVIEWS_FETCHED':
      return {
        ...state,
        reviews: {
          data: action.payload,
          fetched: Date.now()
        }
      };
    case 'NEWS_FETCHED':
      return {
        ...state,
        news: {
          data: action.payload,
          fetched: Date.now()
        }
      };
    case 'RETAILERS_FETCHED':
      return {
        ...state,
        retailers: {
          data: action.payload,
          fetched: Date.now()
        }
      };
    case 'FIRST_TIME_INFO':
      return {
        ...state,
        account: {
          ...state.account,
          firstTimeInfoShowed: Date.now()
        }
      };
    case 'VERSION_CHECKED':
      return {
        ...state,
        settings: {
          ...state.settings,
          versionAvailable: action.payload,
          versionLastChecked: Date.now()
        }
      };
    case 'AFFILIATE':
      return {
        ...state,
        affiliate: action.payload === null ? null : { name: action.payload, added: Date.now() }
      };
    case 'CART':
      return {
        ...state,
        cart: action.payload
      };
    case 'CART_USER':
      return {
        ...state,
        cartUser: action.payload
      };
    case 'LAST_PATH':
      return {
        ...state,
        lastPath: action.payload
      };
    case 'TEST_MODE':
      return {
        ...state,
        testMode: action.payload
      };
    case 'CLEAR_STORE':
      return initialState;
    default:
      return state;
  }
}

export function StoreProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, localState || initialState);
  const value = { state, dispatch };

  useEffect(() => {
    try {
      localStorage.setItem('gh', JSON.stringify(state));
    } catch (e) {
      if (isQuotaExceeded(e)) {
        // Storage full
        if (!localStorage.getItem('storage-full-reload')) {
          localStorage.clear();
          localStorage.setItem('storage-full-reload', true);
          window.location.reload();
        }
      }
    }
  }, [state]);

  return <Store.Provider value={value}>{children}</Store.Provider>;
}
