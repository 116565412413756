/* eslint-disable no-console */
import React, { useEffect, useContext } from 'react';
import { withLocalize } from 'react-localize-redux';

import axios from 'axios';
import cmp from 'semver-compare';

import { Store } from '../Store';
// import sv from '../assets/lang/sv.json';
// import en from '../assets/lang/en.json';
// import dk from '../assets/lang/dk.json';
// import no from '../assets/lang/no.json';
// import fi from '../assets/lang/fi.json';
// import cs from '../assets/lang/cs.json';
// import de from '../assets/lang/de.json';

// const languages = {
//   sv,
//   en,
//   dk,
//   no,
//   fi,
//   cs,
//   de
// };

function VersionCheck({ language, path }) {
  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    console.log('App version: ', process.env.REACT_APP_VERSION, process.env.REACT_APP_ENVIRONMENT);
    // console.log('Language: ', language, ' Publish date: ', languages[language]?.DATE);
  }, [state.settings.version, language]);

  // useEffect(() => {
  //   if (!language || !process.env.REACT_APP_ENVIRONMENT) return;

  //   try {
  //     axios
  //       .get(`/language-files/${language}.json`)
  //       .then((response) => {
  //         const lang = response.data;
  //         if (lang?.DATE > languages[language]?.DATE) {
  //           // eslint-disable-next-line no-console
  //           console.info(`Updating language ${language} to date ${lang.DATE}`);
  //           addTranslationForLanguage(lang, language);
  //         }
  //       })
  //       .catch(() => {});
  //   } catch {}
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [language]);

  useEffect(() => {
    // Check only if it's at least 30 minutes since last check
    if (
      process.env.REACT_APP_ENVIRONMENT &&
      (!state.settings.versionLastChecked || Date.now() - state.settings.versionLastChecked > 60000 * 30)
    ) {
      try {
        axios.get(`/version-web.txt`).then((response) => {
          const v = response.data;
          if (v?.version && cmp(v.version, process.env.REACT_APP_VERSION)) {
            dispatch({ type: 'VERSION_CHECKED', payload: v.version });
          }
        });
      } catch {}
    }
  }, [path, language, state.settings.version, state.settings.versionLastChecked, dispatch]);

  return <></>;
}

export default withLocalize(VersionCheck);
